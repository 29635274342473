import React from 'react';
import ContentArea from '../Shared/ContentArea/ContentArea';
import StandardPageModel from './Models/StandardPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { media } from '@glitz/core';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import { styled, theme } from '../Theme';
import Grid from '../Shared/Grid/Grid';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';

function StandardPage() {
  const {
    pageHeading,
    pageIntroText,
    contentArea,
    inEditMode,
    bodyText,
  } = useCurrentPage<StandardPageModel>();

  return (
    <>
      {(pageHeading || pageIntroText || bodyText) && (
        <TopContent>
          {pageHeading && (
            <>
              <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
                {pageHeading}
              </Heading>
              <StyledDivider />
            </>
          )}
          {pageIntroText && (
            <Intro {...applyEditModeAttr(inEditMode && 'IntroText')}>
              {pageIntroText}
            </Intro>
          )}
          {bodyText && (
            <BodyText>
              <XhtmlComponent
                {...applyEditModeAttr(inEditMode && 'BodyText')}
                content={bodyText}
              />
            </BodyText>
          )}
        </TopContent>
      )}
      <Grid
        type={12}
        data-content-area
        {...applyEditModeAttr(inEditMode && 'MainContent')}
      >
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </Grid>
    </>
  );
}

const TopContent = styled.div({
  margin: { y: theme.spacing(6) },
  width: '100%',
  color: theme.black,
  maxWidth: theme.blockMaxWidthMobile,
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { bottom: theme.spacing(4), top: theme.none, x: 'auto' },
    padding: {
      x: theme.none,
    },
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    padding: {
      x: theme.spacing(4),
    },
    margin: { x: 'auto' },
    maxWidth: theme.contentMaxWidth,
  }),
});

const StyledDivider = styled.div({
  height: theme.spacing(1),
  backgroundColor: theme.blueDark,
  maxWidth: theme.spacing(16),
  margin: { y: theme.spacing(4) },
});

const Heading = styled.h1({
  maxWidth: theme.maxWidthSmall,
  color: theme.blueDark,
  font: { size: theme.theta, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(5.5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota },
    marginBottom: theme.spacing(4),
  }),
});

const Intro = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  marginBottom: theme.spacing(2),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.delta,
  }),
});

const BodyText = styled.div({ width: '100%' });

export default StandardPage;
